import React, { useState } from "react";
import "./home.css";
import Head from "./head";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Home = () => {
  const [color1, setColor1] = useState("notneonn");
  const [color2, setColor2] = useState("notneonn");
  const [color3, setColor3] = useState("notneonn");
  const [color4, setColor4] = useState("notneonn");

  return (
    <div className="flex flex-wrap">
      <div className="tr">
        <div className="flex flex-column">
          <div className="isize pa2">
            <a
              style={{ display: "table-cell" }}
              href="https://www.github.com/rivaanranawat"
              target="_blank"
              rel="noopener noreferrer"
              onMouseOver={() => setColor1("notneonn grow")}
              onMouseOut={() => setColor1("notneonn grow")}
            >
              <FontAwesomeIcon icon={["fab", "github"]} className={color1} />
            </a>
          </div>

          <div className="isize pa2">
            <a
              style={{ display: "table-cell" }}
              href="https://www.linkedin.com/in/rivaan-ranawat/"
              target="_blank"
              rel="noopener noreferrer"
              onMouseOver={() => setColor2("notneonn grow")}
              onMouseOut={() => setColor2("notneonn grow")}
            >
              <FontAwesomeIcon icon={["fab", "linkedin"]} className={color2} />
            </a>
          </div>

          <div className="isize pa2">
            <a
              style={{ display: "table-cell" }}
              href="https://x.com/RanawatRivaan"
              target="_blank"
              rel="noopener noreferrer"
              onMouseOver={() => setColor4("notneonn grow")}
              onMouseOut={() => setColor4("notneonn grow")}
            >
              <FontAwesomeIcon
                icon="fa-brands fa-x-twitter"
                className={color4}
              />
            </a>
          </div>

          <div className="isize pa2">
            <a
              style={{ display: "table-cell" }}
              href="https://www.instagram.com/optimalcoding/"
              target="_blank"
              rel="noopener noreferrer"
              onMouseOver={() => setColor3("notneonn grow")}
              onMouseOut={() => setColor3("notneonn grow")}
            >
              <FontAwesomeIcon icon={["fab", "instagram"]} className={color3} />
            </a>
          </div>
        </div>
      </div>

      <div className="center">
        <Head />
      </div>

      <div className="tr">
        <div className="flex flex-column isize pa2"></div>
      </div>
    </div>
  );
};

export default Home;
