import React from "react";
import Markdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneLight } from "react-syntax-highlighter/dist/esm/styles/prism";

const processText = (text, setActiveTab) => {
  // Array of regex patterns and their corresponding time extraction functions
  const patterns = [
    {
      // HH:MM:SS format with optional "at/time/timestamp" prefix
      regex: /(?:at|time|timestamp)?\s*(\d{1,2}):(\d{2}):(\d{2})/g,
      extract: (match) => {
        const [hours, minutes, seconds] = [match[1], match[2], match[3]];
        return {
          timestamp: `${hours}:${minutes}:${seconds}`,
          seconds:
            parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds),
        };
      },
    },
    {
      // MM:SS format with optional "at/time/timestamp" prefix
      regex: /(?:at|time|timestamp)?\s*(\d{1,2}):(\d{2})(?!\d)/g,
      extract: (match) => {
        const [minutes, seconds] = [match[1], match[2]];
        return {
          timestamp: `${minutes}:${seconds}`,
          seconds: parseInt(minutes) * 60 + parseInt(seconds),
        };
      },
    },
    {
      // XmYs format with optional "at/time/timestamp" prefix
      regex: /(?:at|time|timestamp)?\s*(\d+)m\s*(\d+)s/g,
      extract: (match) => {
        const [minutes, seconds] = [match[1], match[2]];
        return {
          timestamp: `${minutes}m${seconds}s`,
          seconds: parseInt(minutes) * 60 + parseInt(seconds),
        };
      },
    },
    {
      // X seconds format with optional "at/time/timestamp" prefix
      regex: /(?:at|time|timestamp)?\s*(\d+)\s*seconds?/g,
      extract: (match) => {
        const seconds = match[1];
        return {
          timestamp: `${seconds}s`,
          seconds: parseInt(seconds),
        };
      },
    },
    {
      // X minutes format with optional "at/time/timestamp" prefix
      regex: /(?:at|time|timestamp)?\s*(\d+)\s*minutes?/g,
      extract: (match) => {
        const minutes = match[1];
        return {
          timestamp: `${minutes}m`,
          seconds: parseInt(minutes) * 60,
        };
      },
    },
  ];

  const segments = [];
  let lastIndex = 0;

  // Function to find the earliest matching pattern
  const findEarliestMatch = (text, startIndex) => {
    let earliestMatch = null;
    let matchingPattern = null;

    patterns.forEach((pattern) => {
      pattern.regex.lastIndex = startIndex;
      const match = pattern.regex.exec(text);
      if (match && (!earliestMatch || match.index < earliestMatch.index)) {
        earliestMatch = match;
        matchingPattern = pattern;
      }
    });

    return earliestMatch && matchingPattern
      ? { match: earliestMatch, pattern: matchingPattern }
      : null;
  };

  // Process text looking for all timestamp patterns
  let result;
  while ((result = findEarliestMatch(text, lastIndex)) !== null) {
    const { match, pattern } = result;

    // Add text before the timestamp
    if (match.index > lastIndex) {
      segments.push(text.slice(lastIndex, match.index));
    }

    // Extract timestamp info and add TimeStampLink
    const timeInfo = pattern.extract(match);
    segments.push(
      <TimeStampLink
        key={match.index}
        timestamp={timeInfo.timestamp}
        seconds={timeInfo.seconds}
        setActiveTab={setActiveTab}
      />
    );

    lastIndex = match.index + match[0].length;

    // Reset regex lastIndex for next iteration
    // eslint-disable-next-line
    patterns.forEach((p) => (p.regex.lastIndex = lastIndex));
  }

  // Add remaining text
  if (lastIndex < text.length) {
    segments.push(text.slice(lastIndex));
  }

  return segments;
};

const TimeStampLink = ({ timestamp, seconds, setActiveTab }) => {
  const handleClick = () => {
    console.log(`Seeking to ${seconds} seconds (${timestamp})`);
    setActiveTab("video");
  };

  return (
    <span
      onClick={handleClick}
      style={{
        color: "#5b49e3",
        fontWeight: 500,
        cursor: "pointer",
        hover: "underline",
      }}
    >
      {timestamp}
    </span>
  );
};

const processChildren = (children, setActiveTab) => {
  if (typeof children === "string") {
    return processText(children, setActiveTab);
  }

  if (Array.isArray(children)) {
    return children.map((child, index) => {
      if (typeof child === "string") {
        return processText(child, setActiveTab);
      }
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          ...child.props,
          children: processChildren(child.props.children, setActiveTab),
          key: index,
        });
      }
      return child;
    });
  }

  if (React.isValidElement(children)) {
    return React.cloneElement(children, {
      ...children.props,
      children: processChildren(children.props.children, setActiveTab),
    });
  }

  return children;
};

const EnhancedMarkdown = ({ children, setActiveTab }) => {
  return (
    <Markdown
      children={children}
      components={{
        code(props) {
          const { children, className, node, ...rest } = props;
          const match = /language-(\w+)/.exec(className || "");
          return match ? (
            <SyntaxHighlighter
              {...rest}
              PreTag="div"
              children={String(children).replace(/\n$/, "")}
              language={"dart"}
              style={oneLight}
            />
          ) : (
            <code {...rest} className={className}>
              {processChildren(children, setActiveTab)}
            </code>
          );
        },

        p(props) {
          return <p>{processChildren(props.children, setActiveTab)}</p>;
        },
        strong(props) {
          return (
            <strong>{processChildren(props.children, setActiveTab)}</strong>
          );
        },
        em(props) {
          return <em>{processChildren(props.children, setActiveTab)}</em>;
        },
        h1(props) {
          return <h1>{processChildren(props.children, setActiveTab)}</h1>;
        },
        h2(props) {
          return <h2>{processChildren(props.children, setActiveTab)}</h2>;
        },
        h3(props) {
          return <h3>{processChildren(props.children, setActiveTab)}</h3>;
        },
        h4(props) {
          return <h4>{processChildren(props.children, setActiveTab)}</h4>;
        },
        h5(props) {
          return <h5>{processChildren(props.children, setActiveTab)}</h5>;
        },
        h6(props) {
          return <h6>{processChildren(props.children, setActiveTab)}</h6>;
        },
        li(props) {
          console.log(props);
          return <li>{processChildren(props.children, setActiveTab)}</li>;
        },
        blockquote(props) {
          return (
            <blockquote>
              {processChildren(props.children, setActiveTab)}
            </blockquote>
          );
        },
        a(props) {
          return (
            <a {...props}>{processChildren(props.children, setActiveTab)}</a>
          );
        },
        span(props) {
          return <span>{processChildren(props.children, setActiveTab)}</span>;
        },
      }}
    />
  );
};

export default EnhancedMarkdown;
