import { faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EnhancedMarkdown from "./enhanced_markdown";
import backend_url from "../../../config";

const ChatTab = ({
  messages,
  onSendMessage,
  nodeData,
  currentTime,
  setCurrentTime,
  setActiveTab,
}) => {
  const [inputMessage, setInputMessage] = useState("");
  const [isMessageLoading, setIsMessageLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);

  // Handle viewport adjustments for mobile
  useEffect(() => {
    const handleResize = () => {
      // Check if it's a mobile device
      if (window.innerWidth <= 768) {
        // Scroll to input when focused
        const handleFocus = () => {
          // Use setTimeout to ensure this runs after the keyboard appears
          setTimeout(() => {
            inputRef.current?.scrollIntoView({ behavior: "smooth" });
            // Additional scroll to ensure the input is visible above the keyboard
            window.scrollTo(0, document.body.scrollHeight);
          }, 100);
        };

        inputRef.current?.addEventListener("focus", handleFocus);

        return () => {
          inputRef.current?.removeEventListener("focus", handleFocus);
        };
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial setup

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    if (hours > 0) {
      return `${hours}:${minutes.toString().padStart(2, "0")}:${remainingSeconds
        .toString()
        .padStart(2, "0")}`;
    }
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    if (currentTime !== 0) {
      handleSendMessage(
        null,
        `Explain in detail what happens on the timestamp ${formatTime(
          currentTime
        )}`
      );
      setCurrentTime(0);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(scrollToBottom, [messages]);

  const notify = (errorMessage) =>
    toast.error(errorMessage, {
      position: "top-right",
      autoClose: 3000,
      closeOnClick: true,
      theme: "light",
      style: {
        backgroundColor: "#fef2f2",
        color: "#991b1b",
        border: "1px solid #fee2e2",
        borderRadius: "6px",
        padding: "16px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
        fontSize: "14px",
        fontWeight: 500,
      },
    });

  const handleSendMessage = (e, inputMessage) => {
    e?.preventDefault();

    if (inputMessage.trim() !== "") {
      onSendMessage(inputMessage.trim(), "user", nodeData.videoId);
      setIsMessageLoading(true);

      axios
        .post(`${backend_url}/query`, {
          question: inputMessage.trim(),
          video_id: nodeData.videoId,
          messages: messages,
        })
        .then((data) => {
          onSendMessage(data.data.answer, "ai", nodeData.videoId);
          setIsMessageLoading(false);

          axios
            .post(`${backend_url}/store-queries`, {
              question: inputMessage.trim(),
              answer: data.data.answer,
              node_id: nodeData.videoId,
            })
            .then((userQueryRes) => {})
            .catch((e) => {
              setIsMessageLoading(false);
              notify(e.response.data.detail);
            });
        })
        .catch((e) => {
          setIsMessageLoading(false);
          notify(e.response.data.detail);
        });

      setInputMessage("");
    }
  };

  return (
    <div className="chat-tab">
      <ToastContainer />
      <div className="messages-container">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`message ${message.sender}`}
            style={{
              paddingBottom: "0px",
              paddingTop: "0px",
              marginTop: "5px",
            }}
          >
            {message.sender === "ai" ? (
              <EnhancedMarkdown setActiveTab={setActiveTab}>
                {message.text}
              </EnhancedMarkdown>
            ) : (
              <p>{message.text}</p>
            )}
          </div>
        ))}
        {isMessageLoading && (
          <div className="skeleton-wrapper">
            <div className="skeleton-content">
              <div className="skeleton-text"></div>
              <div className="skeleton-text"></div>
              <div className="skeleton-text"></div>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      <form
        onSubmit={(e) => handleSendMessage(e, inputMessage)}
        className="message-input-container"
      >
        <input
          ref={inputRef}
          type="text"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          placeholder="Type your message..."
          className="message-input"
        />
        <button type="submit" className="send-button">
          <FontAwesomeIcon icon={faShare} color={"#5b49e3"} />
        </button>
      </form>
    </div>
  );
};

export default ChatTab;
