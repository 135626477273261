import React, { useState } from "react";
import "./SidePanel.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faVideo } from "@fortawesome/free-solid-svg-icons";
import ChatTab from "./ChatTab";
import YouTubePlayer from "./YouTubePlayer";

const SidePanel = ({ node, onClose }) => {
  const [activeTab, setActiveTab] = useState("video");
  const [messages, setMessages] = useState([
    {
      sender: "ai",
      text: `Hello! I'm your expert educational assistant. Based on the video "${node?.data.label}", I'll help you understand and navigate through the educational material. Feel free to ask any questions, and I'll give you detailed answers by analyzing the video and GitHub repository, ensuring you get clear explanations and relevant code examples.`,
      nodeId: node?.data.videoId,
    },
  ]);

  const [currentTime, setCurrentTime] = useState(0);

  const handleSendMessage = (message, sender, nodeId) => {
    setMessages((prevState) => [
      ...prevState,
      { text: message, sender: sender, nodeId: nodeId },
    ]);
  };

  if (!node) return null;
  const nodeData = node.data;

  return (
    <div className={`panel-container ${node ? "open" : ""}`}>
      <div className="tab-container">
        <button
          className={`tab-button ${activeTab === "video" ? "active" : ""}`}
          onClick={() => setActiveTab("video")}
        >
          <FontAwesomeIcon icon={faVideo} /> Video
        </button>
        <button
          className={`tab-button ${activeTab === "chat" ? "active" : ""}`}
          onClick={() => setActiveTab("chat")}
        >
          <FontAwesomeIcon icon={faComment} /> Chat
        </button>
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
      </div>
      <div style={{ display: activeTab === "video" ? "block" : "none" }}>
        <h2 className="header">{nodeData.label}</h2>
        <p className="length" style={{ marginBottom: "0px", marginTop: "0px" }}>
          Length: {nodeData.length}
        </p>
        <p className="description">{nodeData.description}</p>
        <ol className="panel-list-keypoints">
          {nodeData.keyPoints.map((point, index) => (
            <li key={index} className="list-item">
              {point}
            </li>
          ))}
        </ol>

        <YouTubePlayer
          videoId={nodeData.videoId}
          playlistId={nodeData.playlistId}
          setActiveTab={setActiveTab}
          setCurrentTime={setCurrentTime}
        />
      </div>
      {activeTab === "chat" && (
        <ChatTab
          messages={messages.filter((val) => val.nodeId === nodeData.videoId)}
          onSendMessage={handleSendMessage}
          nodeData={nodeData}
          currentTime={currentTime}
          setCurrentTime={setCurrentTime}
          setActiveTab={setActiveTab}
        />
      )}
    </div>
  );
};

export default SidePanel;
