import React from "react";

import { Fade } from "react-awesome-reveal";
import "../blog/Blog.css";
import "../../../index.css";

const About = () => {
  return (
    <Fade big>
      <div
        className="services__box1 aboutdiv"
        style={{
          borderRadius: "20px",
          padding: "20px",
          height: "auto",
        }}
      >
        <p>
          I am an undergrad (currently a sophomore) at Michigan State
          University, majoring in Computer Science.
        </p>
        <p>
          I run a YouTube channel with over 50K subscribers sharing{" "}
          comprehensive programming tutorials. I love to know anything and
          everything about CS.
        </p>
        <p>
          In my freshman year, I was a software engineering intern at NVIDIA
          where I helped build a prototype architecture for long standing
          distributed test automation framework which is responsible for more
          than 50% traffic at LSF NVIDIA HPC clusters.
        </p>
      </div>
    </Fade>
  );
};

export default About;
