import { useState, useCallback, useEffect } from "react";
import {
  ReactFlow,
  addEdge,
  useEdgesState,
  useNodesState,
  Handle,
} from "@xyflow/react";
import dagre from "dagre";
import "@xyflow/react/dist/style.css";
import SidePanel from "./SidePanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useWindowDimensions from "../../../use_window_dimensions";

const beginningNumber = 0;

const CustomNode = ({ data }) => {
  return (
    <div
      className="aboutdiv services__box1"
      style={{
        borderRadius: "10px",
        padding: "10px",
        margin: "0px",
        height: "80px",
        width: "200px",
        lineHeight: "1.2rem",
        fontSize: "16px",
        textAlign: "center",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        border: "3px solid #5B49E3",
        backgroundColor: "#fff",
        transition: "all 0.3s ease",
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = "scale(1.05)";
        e.currentTarget.style.boxShadow = "0 6px 12px rgba(0,0,0,0.15)";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = "scale(1)";
        e.currentTarget.style.boxShadow = "0 4px 8px rgba(0,0,0,0.1)";
      }}
    >
      <Handle type="target" position="top" style={{ opacity: 0 }} />
      <p
        style={{
          margin: 0,
          maxHeight: "60px",
          overflow: "hidden",
          color: "#2c3e50",
        }}
      >
        <b>{data.label}</b>
      </p>
      <Handle type="source" position="bottom" style={{ opacity: 0 }} />
    </div>
  );
};

const initNodes = [
  {
    id: `${beginningNumber}a`,
    data: {
      label: "Introduction to Dart & Flutter",
      videoId: "CzRQ9mnmh44",
      description:
        "This course covers Dart programming and Flutter app development, starting from the basics and progressing to advanced concepts. It includes hands-on projects like a currency converter, weather app, and shopping app to reinforce learning. The course is designed for beginners but also caters to experienced programmers, offering insights into Flutter's inner workings.",
      length: "20 hours",
      keyPoints: [
        "Dart programming fundamentals",
        "Object-Oriented Programming (OOP) in Dart",
        "Flutter framework basics and widgets",
        "State management in Flutter",
        "UI design and layout principles",
        "API integration and data handling",
        "Navigation and routing in Flutter apps",
        "Responsive design for multiple platforms",
        "Flutter's rendering process and widget lifecycle",
        "BuildContext Brief Overview",
      ],
      githubRepo: "https://github.com/RivaanRanawat/flutter_beginners_course",
    },
    position: { x: 250, y: 0 },
    type: "custom",
  },
  {
    id: `${beginningNumber + 1}a`,
    data: {
      label: "WhatsApp UI Clone",
      videoId: "g1hmeP8WZHU",
      description:
        "Since you are familiar with Dart & Flutter, let's get more practice building responsive user interface. This project focuses on recreating the WhatsApp user interface for Android, iOS & Web.",
      length: "1 hour 30 min",
      keyPoints: [
        "General project structure for cross-platform development",
        "Creating responsive layouts for different screen sizes",
        "Implementing different components for different platforms",
        "Deploying a Flutter web app to Netlify",
      ],
      githubRepo: "https://github.com/RivaanRanawat/whatsapp-flutter-ui",
    },
    position: { x: 250, y: 100 },
    type: "custom",
  },
  {
    id: `${beginningNumber + 2}a`,
    data: {
      label: "Firebase Crash Course",
      videoId: "LFlE8yV7lJY",
      description:
        "In this video, we will take a look at Firebase's core offerings like Firebase Auth, Cloud Firestore and Firebase Storage. We will understand why we need each of these services, how they help us and multiple methods each of its SDKs give us.",
      length: "1 hour 30 min",
      keyPoints: [
        "Setting up Firebase in a Flutter project",
        "Firebase Authentication for signup/sign-in",
        "Cloud Firestore for real-time data storage",
        "Firebase Storage for file uploads",
      ],
      githubRepo: "https://github.com/RivaanRanawat/flutter-firebase-tutorial",
    },
    position: { x: 250, y: 200 },
    type: "custom",
  },
  {
    id: `${beginningNumber + 3}a`,
    data: {
      label: "Instagram Clone",
      videoId: "BBccK1zTgxw",
      description:
        "This tutorial demonstrates building an Instagram clone using Flutter and Firebase. It covers everything we have learnt till now - UI creation, Firebase integration, and responsive design. The app features user authentication, post management, likes and comments",
      length: "7 hours",
      keyPoints: [
        "Theming and customizing user interfaces",
        "Building responsive layouts",
        "State management using Provider",
        "Firebase Auth, Firestore, Storage",
        "Complex Firebase queries",
        "Navigation & Routing",
      ],
      githubRepo: "https://github.com/RivaanRanawat/instagram-flutter-clone",
    },
    position: { x: 250, y: 300 },
    type: "custom",
  },
  {
    id: `${beginningNumber + 4}a`,
    data: {
      label: "SOLID Principles",
      videoId: "RhXh09PMI1I",
      description:
        "You know how to build feature-rich applications. Let's focus on building a better codebase now. This tutorial covers the SOLID principles of object-oriented programming and design, which can help in making your codebase solid.",
      length: "30 min",
      keyPoints: [
        "Single Responsibility Principle (SRP)",
        "Open & Closed Principle (OCP)",
        "Liskov Substitution Principle (LSP)",
        "Interface Segregation Principle (ISP)",
        "Dependency Inversion Principle (DIP)",
      ],
      githubRepo: "https://github.com/RivaanRanawat/flutter-solid-principles",
    },
    position: { x: 250, y: 400 },
    type: "custom",
  },
  {
    id: `${beginningNumber + 4}b`,
    data: {
      label: "Unit, Widget & Integration Testing",
      videoId: "mxTW020pyuc",
      description:
        "In SOLID Principles video, I told you all of these principles can be helpful in Testing. Let's see how you can test your code. There are 3 videos in this. Check all of them out!",
      length: "2 hours",
      keyPoints: [
        "Unit Testing",
        "Widget Testing",
        "Integration Testing",
        "Making your code testable",
      ],
      playlistId: "PLlzmAWV2yTgAW2rVT0sqRmtBXc-pmBnJG",
      githubRepo: "https://github.com/RivaanRanawat/flutter_testing",
    },
    position: { x: 250, y: 400 },
    type: "custom",
  },
  {
    id: `${beginningNumber + 5}a`,
    data: {
      label: "Riverpod Crash Course",
      videoId: "pwflXIA-6YQ",
      description:
        "You know state management using Provider. Now, let's learn about another state management tool - Riverpod (anagram of Provider and better). This video covers the core concepts of Riverpod and how to effectively use it in Flutter applications.",
      length: "2 hours",
      keyPoints: [
        "Introduction to Riverpod and its advantages",
        "Providers and their types (Provider, StateProvider, FutureProvider etc.)",
        "Riverpod Generator & Annotations",
        "ProviderScope, AsyncValue, Refs, Modifiers, ProviderObserver",
      ],
    },
    position: { x: 100, y: 500 },
    type: "custom",
  },
  {
    id: `${beginningNumber + 5}b`,
    data: {
      label: "Bloc Crash Course",
      videoId: "SDk_GldOtK8",
      description:
        "There's not just Provider and Riverpod as popular state management tools! There's also Bloc. In this tutorial, we will learn about the Bloc state management tool along with the BLoC Architecture/Pattern by creating 4 simple projects.",
      length: "2 hours 30 min",
      keyPoints: [
        "Setting up Bloc in a Flutter project",
        "Using various Bloc components",
        "Cubits, Bloc vs Cubit comparison",
        "BLoC Architecture Pattern",
      ],
      githubRepo: "https://github.com/RivaanRanawat/flutter_bloc_tutorial",
    },
    position: { x: 400, y: 500 },
    type: "custom",
  },
  {
    id: `${beginningNumber + 6}a`,
    data: {
      label: "Reddit Clone",
      videoId: "B8Sx7wGiY-s",
      description:
        "In this course, you'll learn how to build a responsive Reddit clone with Flutter, Firebase and Riverpod offering features like Google Sign-In, guest access, and community interactions.",
      length: "9 hours 30 min",
      keyPoints: [
        "Firebase Auth, Google Sign In, Firestore, Storage",
        "State management using Riverpod",
        "Responsive UI design",
        "Navigator 2.0 routing",
        "Community creation and moderation",
        "Posting and interacting with content (upvotes, downvotes, comments, awards)",
        "Theme toggling (dark/light mode)",
        "Handling guest and user-specific features",
      ],
      githubRepo: "https://github.com/RivaanRanawat/flutter-reddit-clone",
    },
    position: { x: 100, y: 600 },
    type: "custom",
  },
  {
    id: `${beginningNumber + 6}b`,
    data: {
      label: "Blog App with Clean Architecture",
      videoId: "ELFORM9fmss",
      description:
        "This course focuses on building a blog application using Flutter, Supabase and Bloc while implementing Clean Architecture principles. Clean Architecture is one of the most popular architectures emphasising what you should keep in mind while coding your applications. The key takeaway is that there's more to a production ready application than what we have already learnt.",
      length: "7 hours",
      keyPoints: [
        "Clean Architecture (Data, Domain, Presentation Layers)",
        "Implementing caching strategies",
        "Supabase setup and integration, SQL",
        "Implementing SOLID Principles in Clean Architecture",
        "Dependency Injection using GetIt",
        "Internet Connection Handling and UI updates",
      ],
      githubRepo:
        "https://github.com/RivaanRanawat/blog-app-clean-architecture",
    },
    position: { x: 400, y: 600 },
    type: "custom",
  },
  {
    id: `${beginningNumber + 7}a`,
    data: {
      label: "Animations Crash Course",
      videoId: "pv4NhV86ZKg",
      description:
        "In this tutorial, you'll explore various animation techniques in Flutter, starting from implicit animations for smooth effects to explicit animations for full control. The video also covers page transitions and animating drawings using Custom Painter.",
      length: "3 hours",
      keyPoints: [
        "Implicit animations and its implementation",
        "Explicit animations for detailed control",
        "TweenAnimationBuilder for animated transitions",
        "Staggered animations in lists",
        "Page route animations with explicit animations",
        "Loading animations and their use cases",
        "Custom Painter for creating animated graphics",
        "Differences between implicit and explicit animations",
      ],
      githubRepo:
        "https://github.com/RivaanRanawat/animations_final_source_code",
    },
    position: { x: 250, y: 700 },
    type: "custom",
  },
  {
    id: `${beginningNumber + 8}a`,
    data: {
      label: "Python Crash Course",
      videoId: "c2zRCespYas",
      description:
        "To learn how to use Flutter with custom backend, we need to learn about the language the custom backend will be implemented in. In this Python tutorial, you'll learn essential concepts through visual explanations, challenges, and solutions. The tutorial emphasizes foundational knowledge to prepare you for any Python-related field.",
      length: "10 hours",
      keyPoints: [
        "Running Python programs and PyCharm installation",
        "Basic input/output (print, comments, user input)",
        "Variables and data types (numbers, strings, booleans)",
        "Operators and operator precedence",
        "Conditional statements and control flow",
        "Lists, tuples, sets, and dictionaries",
        "Loops (while and for loops) and list comprehension",
        "Functions and variable scope",
        "Object-oriented programming (OOP) concepts",
      ],
    },
    position: { x: 100, y: 800 },
    type: "custom",
  },
  {
    id: `${beginningNumber + 8}b`,
    data: {
      label: "JavaScript Crash Course",
      videoId: "W6NZfCO5SIk",
      description:
        "If you want to learn about implementing custom backend with NodeJS, you need to learn JavaScript! Currently, I don't have a course on it. So, I am putting a short tutorial by Mosh Hamedani which will help you get a feel of the language and follow along with the next step.",
      length: "1 hour",
      keyPoints: [
        "JavaScript syntax and data types",
        "Objects, Arrays",
        "Variables, Constants, Dynamic Typing",
        "Functions",
      ],
    },
    position: { x: 400, y: 800 },
    type: "custom",
  },
  {
    id: `${beginningNumber + 9}a`,
    data: {
      label: "Spotify Clone with MVVM Architecture",
      videoId: "CWvlOU2Y3Ik",
      description:
        "This course teaches you how to build a Spotify clone using Flutter, FastAPI, PostgreSQL, Riverpod and implementing the MVVM (Model-View-ViewModel) architecture.",
      length: "10 hours",
      keyPoints: [
        "MVVM architecture in Flutter",
        "User authentication (signup/login) with JWT",
        "Audio file handling and uploading using Cloudinary",
        "Using Riverpod Generators for state management",
        "Favorite music functionality and its integration with the backend",
        "Implementing a music player UI and functionalities",
        "Storing and fetching local data using Hive",
        "Integrating PostgreSQL and SQLAlchemy with FastAPI",
        "Implementing Implicit Animations",
        "Implementing audio playback, control and playing music in the background",
      ],
      githubRepo:
        "https://github.com/RivaanRanawat/flutter-spotify-clone-tutorial",
    },
    position: { x: 100, y: 900 },
    type: "custom",
  },
  {
    id: `${beginningNumber + 9}b`,
    data: {
      label: "Amazon Clone using NodeJS",
      videoId: "O3nmP-lZAdg",
      description:
        "This project focuses on building an Amazon clone using Node.js for the backend. It covers server-side development, database management, and integration with a frontend application.",
      length: "11 hours 30 min",
      keyPoints: [
        "Building a Node.js server with Express",
        "Database designing with MongoDB",
        "Implementing authentication (sign up and sign in)",
        "Admin functionalities for product management",
        "Product searching, displaying categories, and ratings",
        "Implementing shopping cart features and payment integration (GPay/Apple Pay)",
        "Deployment to Heroku",
      ],
      githubRepo:
        "https://github.com/RivaanRanawat/flutter-amazon-clone-tutorial",
    },
    position: { x: 400, y: 900 },
    type: "custom",
  },
];

const initEdges = [
  {
    id: "e1-2",
    source: "0a",
    target: "1a",
  },
  {
    id: "e1-3",
    source: "1a",
    target: "2a",
  },
  {
    id: "e2-3",
    source: "2a",
    target: "3a",
  },
  {
    id: "e3-4",
    source: "3a",
    target: "4a",
  },
  {
    id: "e3-4-alt",
    source: "4a",
    target: "4b",
  },
  {
    id: "e4b-5-alt",
    source: "4b",
    target: "5b",
  },
  {
    id: "e4-5",
    source: "4b",
    target: "5a",
  },
  {
    id: "e5-6",
    source: "5a",
    target: "6a",
  },
  {
    id: "e5-6-alt",
    source: "5b",
    target: "6b",
  },
  {
    id: "e6-7",
    source: "6a",
    target: "7a",
  },
  {
    id: "e6-7-alt",
    source: "6b",
    target: "7a",
  },
  {
    id: "e7-8",
    source: "7a",
    target: "8a",
  },
  {
    id: "e7-8-alt",
    source: "7a",
    target: "8b",
  },
  {
    id: "e8-9",
    source: "8a",
    target: "9a",
  },
  {
    id: "e8-9-alt",
    source: "8b",
    target: "9b",
  },
];

const getLayoutedElements = (nodes, edges, direction = "TB") => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 200;
  const nodeHeight = 80;

  dagreGraph.setGraph({
    rankdir: direction,
    ranksep: 50, // Vertical spacing between nodes
    nodesep: 100, // Horizontal spacing between nodes
    edgesep: 80, // Spacing between edges
    marginx: 20, // Horizontal margin
    marginy: 20, // Vertical margin
  });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };
    return node;
  });

  return { nodes, edges };
};

const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
  initNodes,
  initEdges
);

const edgeOptions = {
  style: {
    strokeWidth: 3,
    stroke: "#5B49E3",
  },
  // markerEnd: {
  //   type: MarkerType.ArrowClosed,
  //   color: "#5B49E3",
  //   width: 20,
  //   height: 20,
  // },
};

function Roadmap() {
  /* eslint-disable-next-line no-unused-vars */
  const [nodes, _, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);
  const [selectedNode, setSelectedNode] = useState(null);
  const windowDimensions = useWindowDimensions();

  const [color1, setColor1] = useState("notneonn");
  const [color2, setColor2] = useState("notneonn");
  const [color3, setColor3] = useState("notneonn");

  const [currentTime, setCurrentTime] = useState(0);
  // const [color4, setColor4] = useState("notneonn");

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  const nodeTypes = {
    custom: CustomNode,
  };

  const fitViewOptions = {
    padding: 0.2,
    includeHiddenNodes: false,
    minZoom: 0.57,
    maxZoom: 1,
  };

  const onNodeClick = (event, node) => {
    setSelectedNode(node);
  };

  useEffect(() => {
    document.title = "Rivaan Ranawat Roadmap";
  }, []);

  return (
    <div className="flex flex-wrap relative">
      <div style={{ width: "100%", height: "100vh", position: "relative" }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          fitView
          fitViewOptions={fitViewOptions}
          defaultEdgeOptions={edgeOptions}
          onNodeClick={onNodeClick}
        />
        <SidePanel
          key={selectedNode?.id}
          node={selectedNode}
          onClose={() => setSelectedNode(null)}
          currentTime={currentTime}
          setCurrentTime={setCurrentTime}
        />
      </div>
      <div className="tr absolute top-4 left-4 flex flex-col space-y-4">
        <div
          className="flex flex-column"
          style={{
            visibility:
              selectedNode && windowDimensions.width < 768 ? "hidden" : null,
          }}
        >
          <div className="isize pa2">
            <a
              style={{ display: "table-cell" }}
              href="https://youtube.com/@RivaanRanawat"
              target="_blank"
              rel="noopener noreferrer"
              onMouseOver={() => setColor3("notneonn grow")}
              onMouseOut={() => setColor3("notneonn grow")}
            >
              <FontAwesomeIcon icon={["fab", "youtube"]} className={color3} />
            </a>
          </div>
          <div className="isize pa2">
            <a
              style={{ display: "table-cell" }}
              href="https://www.github.com/rivaanranawat"
              target="_blank"
              rel="noopener noreferrer"
              onMouseOver={() => setColor1("notneonn grow")}
              onMouseOut={() => setColor1("notneonn grow")}
            >
              <FontAwesomeIcon icon={["fab", "github"]} className={color1} />
            </a>
          </div>

          <div className="isize pa2">
            <a
              style={{ display: "table-cell" }}
              href="https://www.linkedin.com/in/rivaan-ranawat/"
              target="_blank"
              rel="noopener noreferrer"
              onMouseOver={() => setColor2("notneonn grow")}
              onMouseOut={() => setColor2("notneonn grow")}
            >
              <FontAwesomeIcon icon={["fab", "linkedin"]} className={color2} />
            </a>
          </div>
          <div className="isize pa2">
            <a
              style={{ display: "table-cell" }}
              href="https://discord.gg/Q8Rx8YWFVF"
              target="_blank"
              rel="noopener noreferrer"
              onMouseOver={() => setColor3("notneonn grow")}
              onMouseOut={() => setColor3("notneonn grow")}
            >
              <FontAwesomeIcon icon={["fab", "discord"]} className={color3} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Roadmap;
