import React, { useEffect, useState } from "react";
import "../blog/Blog.css";
import "../../../index.css";
import "../../home.css";
import { FaYoutube } from "react-icons/fa";
import axios from "axios";
import { SiYoutubeshorts } from "react-icons/si";

import { Fade } from "react-awesome-reveal";

export default function YouTube() {
  const [arr, setArr] = useState([]);
  const [isShowAllVideos, setIsShowAllVideos] = useState(false);

  const url =
    "https://youtube.googleapis.com/youtube/v3/search?key=AIzaSyAIk4EsruO40NOKa7V-n14nKN1Eh1sHc64&channelId=UC-1kzHtwBY8n0TY5NhYxNaw&part=snippet,id&order=date&maxResults=10";

  useEffect(() => {
    getAllTitle();
  }, []);

  const getAllTitle = () => {
    axios
      .get(url)
      .then((res) => {
        const videoDetailsPromises = res.data.items.map((item) => {
          const videoId = item.id.videoId;
          return axios
            .get(
              `https://youtube.googleapis.com/youtube/v3/videos?key=AIzaSyAIk4EsruO40NOKa7V-n14nKN1Eh1sHc64&id=${videoId}&part=contentDetails`
            )
            .then((videoDetailsRes) => {
              const duration =
                videoDetailsRes.data.items[0].contentDetails.duration;

              const isShort = duration < "PT1M";

              return {
                title: item.snippet.title,
                id: videoId,
                isShort: !isShort,
              };
            });
        });

        Promise.all(videoDetailsPromises)
          .then((videoDataArray) => {
            setArr(videoDataArray);
          })
          .catch((error) =>
            console.error(`Error fetching video details: ${error}`)
          );
      })
      .catch((err) => console.error(`Error: ${err}`));
  };
  console.log(arr);
  if (arr.length > 0) {
    return (
      <Fade>
        <div className="services">
          <div className="container">
            <div className="row bgMain">
              {!isShowAllVideos
                ? arr.slice(0, 6).map((item, idx) => {
                    return (
                      <div className="col-4 bgMain">
                        <a
                          href={`https://www.youtube.com/watch?v=${item["id"]}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="services__box1">
                            <br></br>

                            {item["isShort"] ? (
                              <SiYoutubeshorts className="commonIcons" />
                            ) : (
                              <FaYoutube className="commonIcons" />
                            )}
                            <div className="services__box-header">
                              {item["title"]}
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  })
                : arr.map((item, idx) => {
                    return (
                      <div className="col-4 bgMain">
                        <a
                          href={arr[idx]["link"]}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="services__box1">
                            <br></br>
                            <FaYoutube className="commonIcons" />
                            <div className="services__box-header">
                              {item["title"]}
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  })}
            </div>
            <div
              className="price__btn"
              style={{ display: "flex", justifyContent: "center" }}
              onClick={() => setIsShowAllVideos(!isShowAllVideos)}
            >
              <div className="btn btn-outline">
                {isShowAllVideos ? "See Less" : "See More"}
              </div>
            </div>
          </div>
        </div>
      </Fade>
    );
  } else {
    return <div></div>;
  }
}
