import React, { useEffect } from "react";
import Home from "./components/home";
import "tachyons";
import { Routes, Route, useLocation } from "react-router-dom";
import Roadmap from "./components/navs/roadmap/roadmap";
import initializeAnalytics from "./analytics";
import ReactGA from "react-ga4";

const App = () => {
  const location = useLocation();

  useEffect(() => {
    initializeAnalytics();
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/about" element={<Home />} />
      <Route exact path="/code" element={<Home />} />
      <Route exact path="/blog" element={<Home />} />
      <Route exact path="/youtube" element={<Home />} />
      <Route exact path="/roadmap" element={<Roadmap />} />
    </Routes>
  );
};

export default App;
