import React, { Component } from "react";
import "./home.css";
// import Tilt from "react-tilt";
import { Fade } from "react-awesome-reveal";
import Navbar from "./navbar";

class Head extends Component {
  render() {
    return (
      <div className="tc flex flex-column justify-center">
        <div className="center imgWidth name1">
          <Fade big>
            {/* <Tilt> */}
            <a
              href={"https://www.youtube.com/@RivaanRanawat"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="./images/profile.jpg"
                alt="avatar"
                className="ba shadow-3"
              />
            </a>
            {/* </Tilt> */}
          </Fade>
        </div>

        <div className="name">
          <Fade bottom>
            <div className="mt4">
              <h1 className="myname">Rivaan Ranawat</h1>
            </div>
          </Fade>
        </div>

        <Fade top>
          <div className="pb2 mb2 tbox center">
            <div>
              <span className="mr1">Learn ・</span>
              <span className="mr1">Code ・</span>
              <span className="mr1">Build ・</span>
              <span className="mr1">Sleep ・</span>
              <span className="mr1">Repeat</span>
            </div>
          </div>
        </Fade>

        <Navbar />
      </div>
    );
  }
}

export default Head;
