import React, { useState, useEffect, useRef } from "react";
import { Fade } from "react-awesome-reveal";

const YouTubePlayer = ({
  videoId,
  playlistId,
  setActiveTab,
  setCurrentTime,
}) => {
  const playerRef = useRef(null);
  const [isReady, setIsReady] = useState(false);
  const [isYouTubePlayerLoading, setIsYouTubePlayerLoading] = useState(false);

  useEffect(() => {
    setIsYouTubePlayerLoading(true);
    const loadYouTubeAPI = () => {
      if (!window.YT) {
        const tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      } else {
        createPlayer();
      }

      window.onYouTubeIframeAPIReady = createPlayer;

      setIsYouTubePlayerLoading(false);
    };

    const createPlayer = () => {
      if (playerRef.current) {
        playerRef.current.destroy();
      }

      // Use videoId or playlistId depending on what's available
      const options = {
        playerVars: {
          autoplay: 0,
          controls: 1,
        },
        events: {
          onReady: () => setIsReady(true),
        },
      };

      if (playlistId) {
        options.playerVars.listType = "playlist";
        options.playerVars.list = playlistId;
      } else {
        options.videoId = videoId;
      }

      playerRef.current = new window.YT.Player("youtube-player", options);
    };
    loadYouTubeAPI();

    return () => {
      if (playerRef.current) {
        playerRef.current.destroy();
      }
    };
  }, [videoId, playlistId]); // Recreate player when videoId or playlistId changes

  const handleGetTime = () => {
    if (playerRef.current && isReady) {
      const time = playerRef.current.getCurrentTime();
      setCurrentTime(time);
      setActiveTab("chat");
    }
  };

  return (
    <>
      {!isYouTubePlayerLoading ? (
        <Fade big>
          <div className="video-container">
            <div id="youtube-player" className="video" />
          </div>
          <p
            className="btn btn-outline cta grow"
            style={{
              visibility: "visible",
              animationDelay: "1s",
              animationName: "fadeInUp",
            }}
            disabled={!isReady}
            onClick={handleGetTime}
          >
            Chat with Current Time
          </p>
        </Fade>
      ) : (
        <div className="skeleton-wrapper">
          <div className="skeleton-header"></div>
        </div>
      )}
    </>
  );
};

export default YouTubePlayer;
