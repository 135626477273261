import React from "react";
import "./home.css";
import { Link } from "react-router-dom";
import Code from "./navs/code/code";
import About from "./navs/about/about";

import { Fade } from "react-awesome-reveal";
import Blog from "./navs/blog/blog";
import YouTube from "./navs/youtube/youtube";

const Navbar = () => {
  const current = window.location.pathname;

  return (
    <>
      <Fade bottom>
        <div className="flex flex-wrap justify-center">
          <Link to="/about">
            <p
              className="btn btn-outline cta"
              style={{
                visibility: "visible",
                animationDelay: "1s",
                animationName: "fadeInUp",
              }}
            >
              About
            </p>
          </Link>
          <Link to="/code">
            <p
              className="btn btn-outline cta"
              style={{
                visibility: "visible",
                animationDelay: "1s",
                animationName: "fadeInUp",
              }}
            >
              <code>Code</code>
            </p>
          </Link>
          <Link to="/blog">
            <p
              className="btn btn-outline cta"
              style={{
                visibility: "visible",
                animationDelay: "1s",
                animationName: "fadeInUp",
              }}
            >
              <code>Blog</code>
            </p>
          </Link>
          <Link to="/youtube">
            <p
              className="btn btn-outline cta"
              style={{
                visibility: "visible",
                animationDelay: "1s",
                animationName: "fadeInUp",
              }}
            >
              <code>YouTube</code>
            </p>
          </Link>
          <a href="/roadmap">
            <p
              className="btn btn-outline cta"
              style={{
                visibility: "visible",
                animationDelay: "1s",
                animationName: "fadeInUp",
              }}
            >
              <code>Roadmap</code>
            </p>
          </a>
        </div>
      </Fade>

      <div className="center ma2">
        {current === "/about" ? (
          <About />
        ) : current === "/code" ? (
          <Code />
        ) : current === "/blog" ? (
          <Blog />
        ) : current === "/youtube" ? (
          <YouTube />
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
};

export default Navbar;
